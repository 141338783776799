<template>
    <div class="refundOrder content-index">
        <div class="search-box">
            <Search :search-data="searchData" @searchChange="searchChange"/>
        </div>
        <el-tabs
                v-model="pageData.queryFlag"
                style="padding: 0 10px"
                type="card"
                @tab-click="handleClick"
        >
            <el-tab-pane name="0">
        <span slot="label">
          <span>全部</span>
          <span
          >（{{
              refundCheckStatistics.allCount
                  ? refundCheckStatistics.allCount
                  : 0
              }}）</span
          >
        </span>
                <div class="table-box">
                    <Table
                            v-if="pageData.queryFlag == '0'"
                            :button-type="true"
                            :button-width="150"
                            :current-value="pageData.current"
                            :header-style="headerStyle"
                            :height="''"
                            :loading="loading"
                            :orientation="'center'"
                            :serial-number="true"
                            :table-data="tableData"
                            :title-list="titleListData"
                            :total="total"
                            @handleCurrentChange="handleCurrentChange"
                            @operationClick="operationClick"
                    >
                        <template #refundItem="{ data }">
                            <div>退款编号: {{ data.refundExamineId }}</div>
                            <div>
                                订单编号:
                                <span style="color: #1a5eff">{{ data.orderNumber }}</span>
                            </div>
                        </template>
                        <template #customerInfo="{ data }">
                            <div style="color: #1a5eff">{{ data.userName }}</div>
                            <div>{{ data.userPhone }}</div>
                        </template>
                    </Table>
                </div>
            </el-tab-pane>
            <el-tab-pane name="1">
        <span slot="label">
          <span>待审核</span>
          <span
          >（{{
              refundCheckStatistics.toBeExamineCount
                  ? refundCheckStatistics.toBeExamineCount
                  : 0
              }}）</span
          >
        </span>
                <div class="table-box">
                    <Table
                            :button-type="true"
                            v-if="pageData.queryFlag == '1'"
                            :button-width="150"
                            :current-value="pageData.current"
                            :header-style="headerStyle"
                            :height="''"
                            :loading="loading"
                            :orientation="'center'"
                            :serial-number="true"
                            :table-data="tableData"
                            :title-list="titleListData"
                            :total="total"
                            @handleCurrentChange="handleCurrentChange"
                            @operationClick="operationClick"
                    >
                        <template #refundItem="{ data }">
                            <div>退款编号: {{ data.refundExamineId }}</div>
                            <div>
                                订单编号:
                                <span style="color: #1a5eff">{{ data.orderNumber }}</span>
                            </div>
                        </template>
                        <template #customerInfo="{ data }">
                            <div style="color: #1a5eff">{{ data.userName }}</div>
                            <div>{{ data.userPhone }}</div>
                        </template>
                    </Table>
                </div>
            </el-tab-pane>
            <el-tab-pane name="2">
                <div slot="label">
                    <el-badge
                            :value="refundCheckStatistics.refundSuccessViewCount"
                            class="item"
                            :max="99"
                            :hidden="refundCheckStatistics.refundSuccessViewCount <= 0"
                    >
                        <span>退款成功</span>
                        <span
                        >（{{
                            refundCheckStatistics.refundSuccessCount
                                ? refundCheckStatistics.refundSuccessCount
                                : 0
                            }}）</span
                        >
                    </el-badge>
                </div>
                <div class="table-box">
                    <Table
                            :button-type="true"
                            v-if="pageData.queryFlag == '2'"
                            :button-width="150"
                            :current-value="pageData.current"
                            :header-style="headerStyle"
                            :height="''"
                            :loading="loading"
                            :orientation="'center'"
                            :serial-number="true"
                            :table-data="tableData"
                            :title-list="titleListData"
                            :total="total"
                            @handleCurrentChange="handleCurrentChange"
                            @operationClick="operationClick"
                    >
                        <template #refundItem="{ data }">
                            <div>退款编号: {{ data.refundExamineId }}</div>
                            <div>
                                订单编号:
                                <span style="color: #1a5eff">{{ data.orderNumber }}</span>
                            </div>
                        </template>
                        <template #customerInfo="{ data }">
                            <div style="color: #1a5eff">{{ data.userName }}</div>
                            <div>{{ data.userPhone }}</div>
                        </template>
                    </Table>
                </div>
            </el-tab-pane>
            <el-tab-pane name="3">
                <div slot="label">
                    <el-badge
                            :value="refundCheckStatistics.refundFailViewCount"
                            class="item"
                            :max="99"
                            :hidden="refundCheckStatistics.refundFailViewCount <= 0"
                    >
                        <span>退款失败</span>
                        <span
                        >（{{
                            refundCheckStatistics.refundFailCount
                                ? refundCheckStatistics.refundFailCount
                                : 0
                            }}）</span
                        >
                    </el-badge>
                </div>
                <div class="table-box">
                    <Table
                            :button-type="true"
                            :button-width="150"
                            v-if="pageData.queryFlag == '3'"
                            :current-value="pageData.current"
                            :header-style="headerStyle"
                            :height="''"
                            :loading="loading"
                            :orientation="'center'"
                            :serial-number="true"
                            :table-data="tableData"
                            :title-list="titleListData"
                            :total="total"
                            @handleCurrentChange="handleCurrentChange"
                            @operationClick="operationClick"
                    >
                        <template #refundItem="{ data }">
                            <div>退款编号: {{ data.refundExamineId }}</div>
                            <div>
                                订单编号:
                                <span style="color: #1a5eff">{{ data.orderNumber }}</span>
                            </div>
                        </template>
                        <template #customerInfo="{ data }">
                            <div style="color: #1a5eff">{{ data.userName }}</div>
                            <div>{{ data.userPhone }}</div>
                        </template>
                    </Table>
                </div>
            </el-tab-pane>
            <el-tab-pane name="4">
                <div slot="label">
                    <el-badge :value="refundCheckStatistics.refundCloseViewCount" class="item" :max="99"
                              :hidden="refundCheckStatistics.refundCloseViewCount <= 0">
                        <span>退款关闭</span>
                        <span>（{{
                            refundCheckStatistics.refundCloseCount
                                ? refundCheckStatistics.refundCloseCount
                                : 0
                            }}）</span>
                    </el-badge>
                </div>
                <div class="table-box">
                    <Table
                            :button-type="true"
                            :button-width="150"
                            v-if="pageData.queryFlag == '4'"
                            :current-value="pageData.current"
                            :header-style="headerStyle"
                            :height="''"
                            :loading="loading"
                            :orientation="'center'"
                            :serial-number="true"
                            :table-data="tableData"
                            :title-list="titleListData"
                            :total="total"
                            @handleCurrentChange="handleCurrentChange"
                            @operationClick="operationClick"
                    >
                        <template #refundItem="{ data }">
                            <div>退款编号: {{ data.refundExamineId }}</div>
                            <div>
                                订单编号:
                                <span style="color: #1a5eff">{{ data.orderNumber }}</span>
                            </div>
                        </template>
                        <template #customerInfo="{ data }">
                            <div style="color: #1a5eff">{{ data.userName }}</div>
                            <div>{{ data.userPhone }}</div>
                        </template>
                    </Table>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import Search from "@/components/search";
import Table from "@/components/table2";

import {
    getRefundExamineList,
    delRefundCheck,
    getRefundCheckStatistics,
} from "@/api/clothes/order";

export default {
    name: "RefundOrder",
    components: {
        Search,
        Table,
    },
    data() {
        return {
            searchData: [
                {
                    prop: "orderNumber",
                    label: "订单号/衣物号",
                    type: "text",
                    labelWidth: "100px",
                },
                {
                    prop: "phone",
                    label: "手机号",
                    type: "text",
                    labelWidth: "100px",
                },
                {
                    prop: "time",
                    label: "退款日期",
                    type: "DateTime",
                    labelWidth: "100px",
                },
                {
                    typeList: [
                        {
                            name: "查询",
                            code: "search",
                            type: "primary",
                        },
                        {
                            name: "重置",
                            code: "reset",
                            type: "primary",
                        },
                    ],
                    labelWidth: "20px",
                },
            ],
            pageData: {
                current: 1,
                size: 10,
                queryFlag: "0",
            },
            tableData: [],
            titleListData: [
                {
                    prop: "refundItem",
                    label: "退款项目",
                    type: "slot",
                    width: 150,
                },
                {
                    prop: "createdTime",
                    label: "申请退款时间",
                    width: 150,
                },
                {
                    prop: "customerInfo",
                    label: "顾客信息",
                    type: "slot",
                    width: 120,
                },
                {
                    prop: "orderPrice",
                    label: "实收金额(元)",
                    width: 100,
                },
                {
                    prop: "refundAmount",
                    label: "退款金额(元)",
                    width: 100,
                },
                {
                    prop: "status",
                    label: "审核状态",
                    bool: true,
                },
            ],
            headerStyle: {
                background: "#f5f7fa",
            },
            total: 0,
            loading: false,
            storeId: null,
            refundCheckStatistics: {},
        };
    },
    created() {
        this.storeId = this.$store.state.user.userInfo.storeId;
        this.pageData.storeId = this.storeId;
        this.getRefundExamineListData();
        this.getRefundCheckStatisticsData();
    },
    methods: {
        // 获取退款列表
        getRefundExamineListData() {
            this.loading = true;
            getRefundExamineList({...this.pageData})
                .then(({data}) => {
                    if (data.code == 0) {
                        this.tableData = data.data.records?.map((item) => {
                            item.buttonList = [
                                {
                                    name: "详情",
                                    size: "medium",
                                    type: "text",
                                    code: "details",
                                },
                            ];
                            item.status = item.examineStatusDesc;
                            item.viewStatus = item.status == "待审核" ? 1 : item.storeViewStatus;
                            return item;
                        });
                        this.total = data.data.total;
                    } else {
                        this.$message.error(data.msg);
                    }
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        // 获取去统计信息
        async getRefundCheckStatisticsData() {
            let {data} = await getRefundCheckStatistics({...this.pageData});
            this.refundCheckStatistics = data.data;
        },
        searchChange({code, formData}) {
            if (code == "search") {
                this.pageData = {
                    ...this.pageData,
                    phone: formData.phone,
                    orderNumber: formData.orderNumber,
                    beginTime: formData.time ? formData.time[0] : "",
                    endTime: formData.time ? formData.time[1] : "",
                    current: 1,
                };
            }
            if (code == "reset") {
                this.pageData = {
                    current: 1,
                    size: 10,
                    queryFlag: "0",
                    storeId: this.storeId,
                };
            }
            this.getRefundExamineListData();
            this.getRefundCheckStatisticsData();
        },
        handleClick(tab) {
            this.pageData.current = 1;
            this.pageData.queryFlag = tab.index;
            this.getRefundExamineListData();
            this.getRefundCheckStatisticsData();
        },
        handleCurrentChange(value) {
            this.pageData.current = value;
            this.getRefundExamineListData();
            this.getRefundCheckStatisticsData();
        },
        operationClick({row, code}) {
            if (code == "details") {
                this.$router.push({
                    path: "/home/refundOrderDetails",
                    query: {
                        refundExamineId: row.refundExamineId,
                    },
                });
            } else if (code == "delete") {
                this.$confirm("是否要删除当前订单?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        delRefundCheck(row.refundExamineId).then(({data}) => {
                            if (data.code == 0) {
                                this.getRefundExamineListData();
                                this.getRefundCheckStatisticsData();
                                this.$message({
                                    type: "success",
                                    message: "删除成功!",
                                });
                            } else {
                                this.$message({
                                    type: "error",
                                    message: "删除失败!",
                                });
                            }
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除",
                        });
                    });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.refundOrder {
  padding: 20px;

  .table-box {
    height: calc(100vh - 220px);
    overflow: auto;
  }
}

.el-tabs {
  margin-top: 20px;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: #e4e7ed;
}

::v-deep .el-badge__content.is-fixed.is-dot {
  top: 3px;
  right: 3px;
}

::v-deep .el-badge__content.is-fixed {
  top: 10px;
  right: 10px;
}

::v-deep .el-tabs__item {
  padding: 0 30px !important;
}

::v-deep
.el-tabs--top.el-tabs--card
> .el-tabs__header
.el-tabs__item:last-child {
  padding: 0 30px;
}
</style>
